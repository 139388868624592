import Vue from 'vue'
Vue.config.devtools = true;

import VueResource from 'vue-resource'
Vue.use(VueResource)

/* expose jQuery as a global $ and jQuery */
require("expose-loader?$!jquery");
require("expose-loader?jQuery!jquery");

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import SigninApp from '../components/signin-app.vue'

var app = new Vue({
  el: '#app',
  data: {
    message: "Hello"
  },
  template: '<app />',
  components: {
    'app': SigninApp
  }
});
