<template>
  <div>
    <div id="login-logo">
      <img class="logo" src="/static/img/login-logo.png" />
    </div>
    <div v-if="!shouldShowForgotPasswordForm" id="signin">
      <div class="content">
        <div class="title">
          Log in to Augmentir
        </div>
        <div v-if="singleTenantSsoLoginOnMainScreen.supported">
          <button class="button large single-tenant-sso-button" @click="singleTenantSso()">{{singleTenantSsoLoginOnMainScreen.buttonText}}</button>
        </div>
        <div v-if="singleTenantSsoLoginOnMainScreen.supported" class="other-login-section">
          <span v-if="!showNormalLogin" class="link other-login" @click="showNormalLoginSection()">Other Login</span>
        </div>
        <form v-if="showNormalLogin && !shouldShowForgotPasswordForm" ref="form" id="login-form" class="card-body" method="POST" action="/login" v-on:submit="verifySubmit($event)">
          <div id="login-message" v-if="message">
            <span>{{ message }}</span>
          </div>
          <div v-show="!hasEmailAddress" class="input-row">
            <input id="login-username" ref="email" class="stand-alone" name="username" autocomplete="username" type="text" v-model="email" placeholder="Email" required @keyup.enter="doNext">
          </div>
          <div v-show="hasEmailAddress" class="input-row">
            <div @click="doBack($event)">{{ email }}</div>
            <input id="login-password" ref="password" class="stand-alone" name="password" autocomplete="current-password" type="password" v-model="password" placeholder="Password" required>
          </div>
          <div v-show="!hasEmailAddress && !waitingLoginRedirects" class="actions just-next">
            <button id="login-next" class="button large next-button" @click="doNext($event)">Next</button>
          </div>
          <div v-show="hasEmailAddress" class="actions back-and-next">
            <button class="button large back-button" @click="doBack($event)">Back</button>
            <span class="spacer"></span>
            <button v-if="!isLoggingIn" type="submit" id="login-submit" class="button large log-in-button">log in</button>
          </div>
          <div v-if="hasEmailAddress" id="login-lost" class="secondary-actions">
            <span class="link" @click="showForgotPasswordForm">Forgot Password?</span>
          </div>
          <input name="redirect" type="hidden" v-model="redirect" />
        </form>

      </div>
    </div>
    <div id="forgot-password" v-if="shouldShowForgotPasswordForm">
      <div class="content">
        <div v-if="!sentResetRequest">
          <div class="title">
            Forgot your password?
          </div>
          <div v-if="requestError">
            <span>{{requestError}}</span>
          </div>
          <div v-else class="description">
            Don't worry, resetting your password is easy
          </div>
          <form id="forgot-password-form" class="card-body">
            <div class="input-row">
              <input name="reset-email" type="text" v-model.trim="resetEmailAddress" placeholder="Email" required :class="{'error': resetEmailAddress !== '' && !validResetEmail}" @keyup.enter="sendResetRequest">
            </div>
            <div v-if="resetEmailAddress !== '' && !validResetEmail" class="msg error">
              Invalid email address
            </div>
            <div class="actions">
              <button type="button" class="button large" :class="[{'disabled': !resetEmailAddress || !validResetEmail}]" @click="sendResetRequest">Reset</button>
            </div>
          </form>
          <div id="hideForgot" class="secondary-actions">
            <span class="link" @click="hideForgotPasswordForm">Back to Sign In</span>
          </div>
        </div>
        <div v-else>
          <div v-if="resetRequestError">
            <p>Your request cannot be completed at this time. Please try again later.</p>
          </div>
          <div v-else>
            <p>We have sent a reset password email to <strong>{{ resetEmailAddress }}</strong>. Please click the reset password link to set your new password.</p>
            <p>Didn't receive the email yet?</p>
            <p>Please check your spam folder, or <span class="link" @click="showForgotPasswordForm">resend</span> the email.</p>
          </div>
          <div id="hideForgot" class="secondary-actions">
            <span class="link" @click="hideForgotPasswordForm">Back to Sign In</span>
          </div>
        </div>
      </div>
    </div>
    <!-- No element other than the tooltip should appear in this template after the alert banner -->
    <!-- This is important for z-index -->
    <alert-banner
      v-show="appAlertMsg"
      :msg="appAlertMsg"
      @dismiss-app-alert="dismissAppAlert()"></alert-banner>
    <!-- tooltip should ALWAYS be the very last element in the DOM -->
    <!-- This helps to make sure it stays above all other elements -->
    <tooltip></tooltip>
  </div>
</template>

<script>
import AlertBanner from './elements/alert-banner.vue';
import Tooltip from './elements/tooltip.vue';
import axios from 'axios';
import _ from 'lodash';

export default {
  data() {
    return {
      name: null,
      email: null,
      password: null,
      message: decodeURIComponent(window.ao.message),
      shouldShowForgotPasswordForm: false,
      sentResetRequest: false,
      resetEmailAddress: '',
      requestError: null,
      redirect: null,
      appAlertMsg: null,
      hasEmailAddress: false,
      waitingLoginRedirects: false,
      haveLoginRedirects: false,
      loginRedirects: [],
      isLoggingIn: false,
      showNormalLogin: true,
      singleTenantSsoLoginOnMainScreen: {
        supported: false,
        url: null,
        buttonText: null
      },
      resetRequestError: false
    };
  },
  computed: {
    requestState: function() {
      if (this.validate) {
        return this.resetEmailAddress.length > 0
      } else {
        return true;
      }
    },
    invalidFeedback () {
      if (this.resetEmailAddress.length > 0) {
        return ''
      } else {
        return 'Please enter an email address'
      }
    },
    validEmail: function () {
      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailReg.test(this.email);
    },
    validResetEmail: function () {
      var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailReg.test(this.resetEmailAddress);
    }
  },
  methods: {
    showNormalLoginSection: function() {
      const vm = this;
      vm.showNormalLogin = true;
    },
    verifySubmit: function(evt) {
      const vm = this;
      if (!vm.hasEmailAddress) {
        try {
          evt.preventDefault();
        } catch(e) {}
        return;
      }
      vm.isLoggingIn = true;
    },
    doBack: function(evt) {
      const vm = this;
      vm.hasEmailAddress = false;
      try {
        evt.preventDefault();
      } catch(e) {
        console.error('Exception preventDefault', e);
      }

      // in case there was a server-side error
      vm.isLoggingIn = false;

      vm.$nextTick(function() {
        try {
          vm.$refs['email'].focus();
        } catch (e) {
          console.error('Exception focus on email', e);
        }
      });
    },
    doNext: function(evt) {
      const vm = this;
      var ssoUrl = null;
      if (vm.hasEmailAddress) {
        // shouldn't be getting this if has email
        return;
      }
      try {
        evt.preventDefault();
      } catch(e) {
        console.error('Exception preventDefault', e);
      }

      if (!vm.email) {
        vm.message = 'Must enter an email address';
        return;
      } else {
        vm.message = "";
      }

      // look to see if we should be redirecting the login based on the login email and the list returned from https://app.augmentir.com/api/runtime/getLoginRedirects
      if (vm.loginRedirects) {
        var hasSetServer = false;
        try {
          _.each(vm.loginRedirects, function(lr) {
            if (!hasSetServer) {
              if (lr.regex) {
                let regx = new RegExp(lr.regex, 'i');
                if (regx.test(vm.email.toLowerCase())) {
                  console.log('Successfully setting server to "' + lr.server + '" becasue email "' + vm.email + '" match regex "' + lr.regex + '"')
                  hasSetServer = true;
                  ssoUrl = lr.sso;
                }
              } else if (lr.explicit) {
                if (vm.email.toLowerCase() === lr.explicit.toLowerCase()) {
                  console.log('Successfully setting server to "' + lr.server + '" becasue email "' + vm.email + '" match explicit "' + lr.explicit + '"')
                  hasSetServer = true;
                  ssoUrl = lr.sso;
                }
              } else {
                console.error('Unsupported format for loginRedirectEntry - no regex or explicit "' + JSON.stringify(lr) + '"', e);
              }
            }
          })
        } catch (e) {
          console.error('exception processing loginRedirects "' + JSON.stringify(vm.loginRedirects) + '"', e)
        }
      }
      if (ssoUrl) {
        const ssoRedirect = new URL(ssoUrl);
        if (vm.email) {
          ssoRedirect.searchParams.append("email", vm.email);
        }
        if (vm.redirect) {
          ssoRedirect.searchParams.append("redirect", vm.redirect);
        }
        window.location = ssoRedirect.toString();
      } else {
        vm.hasEmailAddress = true;
        vm.$nextTick(function() {
          try {
            vm.$refs['password'].focus();
          } catch (e) {
            console.error('Exception focus on password', e);
          }
        })
      }
    },
    singleTenantSso: function() {
      const vm = this;
      try {
        window.location = vm.singleTenantSsoLoginOnMainScreen.url;
      } catch (e) {
        alert('Error logging in with SSO');
      }
    },
    doSubmit: function() {
      const vm = this;
      if (!vm.hasEmailAddress) {
        // shouldn't be getting this if we don't have email
        return;
      }
      try {
        vm.$refs['form'].submit();
      } catch (e) {
        console.error('Exception submit form', e);
      }

    },
    showForgotPasswordForm() {
      const vm = this;
      vm.requestError = null;
      vm.resetRequestError = false;
      vm.sentResetRequest = false;
      vm.resetEmailAddress = vm.email;
      vm.shouldShowForgotPasswordForm = true;
      vm.$nextTick(function() {
        // vm.$refs.resetEmailAddressInput.focus()
      })
    },
    hideForgotPasswordForm() {
      this.shouldShowForgotPasswordForm = false;
    },
    sendResetRequest: async function() {
      const vm = this;
      try {
        let response = await vm.$http.post('/api/user/requestresetpassword', { email: vm.resetEmailAddress });
        vm.sentResetRequest = true;
      } catch (err) {
        vm.resetRequestError = true;
        vm.sentResetRequest = true;
      }
    },
    showAppAlert: function (msg) {
      const vm = this;

      vm.appAlertMsg = msg;
    },
    dismissAppAlert: function () {
      const vm = this;

      vm.appAlertMsg = null;
    },
    mobileDeviceCheck: function () {
      // mobile device regex check value pulled from here: http://detectmobilebrowsers.com/
      const vm = this;

      let check = false;
      (function(a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);

      return check;
    }
  },
  mounted: async function() {
    const vm = this;

    if (window.location.search.startsWith('?redirect=')) {
      let override = window.location.search.substring('?redirect='.length);
      let path = window.location.origin + override;
      vm.redirect = override;
    } else {
      vm.redirect = decodeURIComponent(window.ao.redirect) + window.location.hash;
    }

    if (vm.mobileDeviceCheck()) {
      vm.showAppAlert('Unlike the Augmentir mobile application, which is optimized for all sizes of mobile devices, the Augmentir Cloud Platform is optimized for tablets and computers.');
    }

    vm.$nextTick(function() {
      try {
        vm.$refs['email'].focus();
      } catch (e) {
        console.error('Exception focus on email', e);
      }
    });

    vm.$nextTick(async function() {
      try {
        var singleTenantSsoLoginOnMainScreen = await axios.post('/api/user/getSingleTenantSsoLoginOnMainScreen');
        if (singleTenantSsoLoginOnMainScreen && singleTenantSsoLoginOnMainScreen.data) {
          vm.singleTenantSsoLoginOnMainScreen = singleTenantSsoLoginOnMainScreen.data;
          if (vm.singleTenantSsoLoginOnMainScreen.supported) {
            vm.showNormalLogin = false;
          }
        }
      } catch (e) {
        console.error('Exception getting single tenant info', e)
      }
    })

    try {
      var redirectListApi = '/api/runtime/getLoginRedirects';
      console.log('In signin-app.vue');
      vm.waitingLoginRedirects = true;
      console.log('getLoginRedirects: polling "' + redirectListApi + '"')
      var res = await axios.post(redirectListApi);
      vm.waitingLoginRedirects = false;
      var redirectList = res.data.data.redirectList;
      if (redirectList && Array.isArray(redirectList)) {
        vm.haveLoginRedirects = true;
        vm.loginRedirects = redirectList;
      }
    } catch (e) {
      console.error('Exception getting login redirects', e);
    }
  },
  components: {
    AlertBanner,
    Tooltip
  }
}
</script>
<style lang="scss" scoped>
  @import './../styles/app-styles.scss';

  #login-logo {
    padding: 15px;

    text-align: center;

    background-color: rgba(40, 102, 159, 1);

    .logo {
      height: 75px;
    }
  }

  .content {
    padding: 60px;

    color: rgba(65, 65, 65, 1);
    text-align: center;

    .title {
      margin-bottom: 24px;

      font-size: 32px;
      font-weight: 200;
    }

    .description {
      max-width: 385px;
      margin: 0 auto 54px;

      font-size: 16px;
    }

    .legal {
      max-width: 250px;
      margin: 20px auto 0;

      font-size: 12px;
    }

    .switch {
      font-size: 14px;
    }
  }

  input {
    text-align: center;

    border: 0;
    border-bottom-color: rgba(65, 65, 65, .25);
    border-bottom-style: solid;
    border-bottom-width: 1px;

    &.error {
      border-bottom-color: rgba(255, 0, 0, 1);
    }
  }

  .msg {
    width: 450px;
    padding-left: 145px;
    margin: 5px auto 0;

    text-align: left;

    &.error {
      color: rgba(255, 0, 0, 1);
      font-size: 12px;
    }
  }

  #login-form,
  #forgot-password-form {
    width: 475px;
    margin: 0 auto;

    .actions {
      margin: 30px 0;

      &.just-next {
        text-align: right;
      }

      &.back-and-next {
        display: flex;

        .spacer {
          flex: 1;
        }

        .log-in-button {
          margin-right: 55px;
        }
      }
    }

    #login-lost {
      margin-top: 10px;

      font-size: 14px;
    }

    .input-row {
      input {
        width: 320px;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .next-button {
      margin-right: 55px;
    }

    .back-button {
      background-color: #6E757C;
      margin-left: 60px;
    }

    .msg {
      width: 320px;
      padding-left: 0;
    }
  }
  #login-form{
    #login-message {
      padding: 15px;
      color: #FF0000;
      text-align: center;
      line-height: 20px;
      min-height: 50px;
      background: #FF25250D 0% 0% no-repeat padding-box;
      border: 1px solid #FF252555;
      opacity: 1;
      margin-bottom: 30px;
    }
  }

  #forgot-password {
    .secondary-actions {
      text-align: center;
    }
  }

  .other-login-section {
    margin-top: 20px;
    .other-login {
      font-size: 14px;
    }
  }

</style>



